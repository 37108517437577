<template>
  <div>
    <b-card>
      <router-link :to="to">
        <h4 class="text-danger mb-4">تماس های پشتیبانی</h4>
      </router-link>
      <b-table
      responsive
      striped
      outlined
      hover
      
      :items="items"
      :fields="fields">
      <template v-slot:cell(created_at)="data">
        <p v-if="data.item.created_at">{{ data.item.created_at | persianDate}}</p>
      </template>
      <template v-slot:cell(subject)="data">
        <span v-if="data.item.subject == 'suggestion'"> پیشنهاد</span>
        <span v-else-if="data.item.subject == 'complaint'"> شکایت</span>
        <span v-else-if="data.item.subject == 'tracking_order'"> پیگیری سفارش</span>
        <span v-else-if="data.item.subject == 'admin'"> مدیریت</span>
        <span v-else-if="data.item.subject == 'other'"> دیگر</span>
      </template>
      <template v-slot:cell(status)="data">
        <div style="width: 80px;">
            <p v-if="data.item.status == 'pending' " class="py-1 px-2 bg-danger text-center small rounded" >در حال انتظار</p>
            <p v-else-if="data.item.status == 'visited'" class="p-1 bg-warning text-center small rounded" >دیده شده</p>
            <p v-else-if="data.item.status == 'ok' " class="p-1 bg-success text-center small rounded" >تایید شده</p>
        </div>
      </template>
    <template v-slot:cell(edit)="data">
        <button class="btn btn-primary" @click="editContact(data.index)"><i class="fa fa-edit"></i></button>
    </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
  </b-card>
  <b-modal id="edit-contact" hide-footer title="ویرایش تماس با ما">
    <div v-if="edit.id">
        <form @submit.prevent="contactEdit" id="contact-edit">
            <b-form-group>
                <label for="description">توضیح <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-textarea id="description" :disabled="disabled" name="description" v-model="edit.description"></b-form-textarea>
            </b-form-group>
            
            <b-form-group >
                <label for="status">وضعیت <i class="fas fa-asterisk text-danger" style="font-size: 0.5rem;"></i></label>
                <b-form-select id="status" :disabled="disabled" name="status" v-model="edit.status">
                    <b-form-select-option value="pending">درحال انتظار</b-form-select-option>
                    <b-form-select-option value="visited">دیده شده</b-form-select-option>
                    <b-form-select-option value="ok">تایید شده</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <input type="hidden" name="_method" value="PUT">
            <div class="clearfix">
                <button type="submit" :disabled="disabled" class="btn btn-primary float-right">ویرایش</button>
                <b-button class="float-left" variant="white" type="button"  @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
              </div>
        </form>
    </div>
</b-modal>
  </div>
    
</template>
<script>

export default {
  props: ['to', 'items'],
  data () {
    return {
      url:'/api/admin/contacts',
      fields:[
        {key: 'name' , label: 'نام'},
        {key: 'email' , label: 'ایمیل'},
        {key: 'telephone' , label: 'تلفن'},
        {key: 'subject' , label: 'موضوع'},
        {key: 'status' , label: 'وضعیت'},
        {key: 'body' , label: 'متن'},
        {key: 'created_at' , label: 'تاریخ ثبت'},
        {key:"edit", label:"ویرایش"},
      ],
      edit:{},
      disabled: false
    }
  },
  filters: {
      persianDate (date) {
        return window.moment(date).format('HH:mm jYYYY/jMM/jDD ')
      }
    },
  methods:{
    editContact(index) {
      let item = window.clone(this.items[index]);
      item.index = index;
      this.edit = item;
      this.$root.$emit('bv::show::modal', 'edit-contact')
    },
    contactEdit() {
        this.disabled = true
        let form = document.getElementById('contact-edit')
        let formData = new FormData(form)
        this.$axios.post(this.$root.baseUrl + this.url + `/${this.edit.id}`, formData)
          .then(response => {
            if(response.data.data.status == 'pending') {
              this.$root.$set(this.items , this.edit.index , response.data.data)
            } else {
               this.$root.$delete(this.items, this.edit.index)
            }
           
            this.$root.$emit('bv::hide::modal', 'edit-contact')
            this.disabled = false
          })
          .catch(error => {
            this.$root.error_notification(error)
            this.disabled = false
          })
      }
  },
  created(){
    
  }
  
}
</script>
