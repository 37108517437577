<template>
  <div>
    <b-card>
      <router-link :to="to">
        <h4 class="text-danger mb-4">سفارشات جدید</h4>
      </router-link>

      <b-table responsive striped outlined hover :fields="table_fields" :items="items">
        <template v-slot:cell(status)="data">
          <div>
            <p v-if="data.item.status.status == '0' " class="p-1 bg-secondary text-center small rounded">جدید</p>
            <p v-else-if="data.item.status.status == '1'" class="p-1 bg-warning text-center small rounded">در حال انتظار
            </p>
            <p v-else-if="data.item.status.status == '2' " class="p-1 bg-info text-center small rounded">تایید شده</p>
            <p v-else-if="data.item.status.status == '3' " class="p-1 bg-primary text-center small rounded">در حال ارسال
            </p>
            <p v-else-if="data.item.status.status == '4' " class="p-1 bg-success text-center small rounded">تحویل داده
              شده</p>
            <p v-else-if="data.item.status.status == '5' " class="p-1 bg-danger text-center small rounded">کنسل شده</p>
          </div>
        </template>
        <template v-slot:cell(invoice_number)="data">
          <router-link class="link btn-link" :to="'/admin/invoices/' + data.item.id">#{{ data.item.invoice_number }}
          </router-link>
        </template>
        <template v-slot:cell(customer)="data">
          <span v-if="data.item.customer.first_name != null && data.item.customer.last_name != null ">
            {{ data.item.customer.first_name + ' ' + data.item.customer.last_name + ' | دریافت کننده : '+ data.item.name  }}</span>
          <span v-else> {{ 'دریافت کننده : ' + data.item.name  }}</span>
        </template>

        <template v-slot:cell(created_at)="data">
          <p v-if="data.item.created_at">{{ data.item.created_at | persianDate}}</p>
        </template>
        <template v-slot:cell(AmountOrders)="data">
          {{ numberFormat(data.item.AmountOrders)  }}
        </template>

        <template v-slot:cell(details)="data">
          <button class="btn-sm btn btn-info" @click="showInvoiceDetails(data.index)">
            <i class="fa fa-info"></i>
          </button>
        </template>
      </b-table>
      <div class="alert alert-danger text-right" v-if="items == ''">
        <span class="text-right">موردی یافت نشد ! </span>
      </div>
    </b-card>
    <b-modal id="inovice-details" title="جزییات صورت حساب" size="lg" hide-footer>
      <div>
        <table class="table table-bordered" v-if="! $root.isEmptyObject(invoice)">
          <tbody>
            <tr>
              <th colspan="2">شناسه تراکنش</th>
              <td colspan="2">{{ invoice.transaction_id ? invoice.transaction_id : 'ندارد' }}</td>
            </tr>
            <tr>
              <th colspan="2">وضعیت تراکنش</th>
              <td colspan="2">{{ invoice.MessageStatusTransaction ? invoice.MessageStatusTransaction : 'ندارد' }}</td>
            </tr>
            <tr>
              <th colspan="2">وضعیت صورتحساب</th>
              <td colspan="2">
                <form  id="invoice-status" @submit.prevent="invoiceStatus">
                  <b-row>
                    <b-col cols="9">
                      <b-form-group>
                        <b-form-select id="status" name="status" v-model="invoice.status.status" :disabled="disabled">
                          <b-form-select-option value="0">جدید</b-form-select-option>
                          <b-form-select-option value="1">در حال انتظار</b-form-select-option>
                          <b-form-select-option value="2">تایید شده</b-form-select-option>
                          <b-form-select-option value="3">در حال ارسال</b-form-select-option>
                          <b-form-select-option value="4">تحویل داده شده</b-form-select-option>
                          <b-form-select-option value="5">کنسل شده</b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <button class="btn btn-primary" type="submit" :disabled="disabled">تغییر</button>
                    </b-col>
                  </b-row>
                </form>
              </td>
            </tr>

            <tr>
              <th colspan="2">آدرس</th>
              <td colspan="2">{{ invoice.address }}</td>
            </tr>
            <tr> 
              <th>هزینه ارسال (تومان)</th>
              <td> {{ numberFormat(invoice.delivery_amount) }}</td>
              <td>شیوه ارسال</td>
              <td>
                <span v-if="invoice.shipping_method == 'post'">پست معمولی</span>
                <span v-else-if="invoice.shipping_method == 'pishtaz'">پست پیشتاز</span>
                <span v-else-if="invoice.shipping_method == 'tipax'">پست تیپاکس</span>
              </td>
            </tr>
            <tr v-if="invoice.coupon">
              <th colspan="2">کوپن استفاده شده</th>
              <td colspan="2">{{ invoice.coupon.title }} - ({{ invoice.coupon.code }})</td>
            </tr>
            <tr>
              <th colspan="2">توضیحات سفارش</th>
              <td colspan="2">{{ invoice.description ? invoice.description : 'ندارد' }}</td>
            </tr>
            <tr>
              <th colspan="2">شماره پیگیری</th>
              <td colspan="2">{{ invoice.tracking_code ? invoice.tracking_code : 'ندارد' }}</td>
            </tr>
          </tbody>
        </table>

        <div class="text-left">
          <a :href="'/admin/invoice/print/' + invoice.id" target="_blank" class="btn btn-info "><i class="fa fa-print"></i></a>
          <router-link :to="{name: 'order.detail' , params: {id: invoice.id}}" class="btn btn-info mx-1">
            اقلام
          </router-link>
        </div>
      </div>
    </b-modal>
  </div>

</template>
<script>
  export default {
    props: ['to', 'items'],
    data() {
      return {
        url: '/api/admin/orders',
        invoice: {},
        table_fields: [{
            key: 'id',
            label: 'شناسه'
          },
          {
            key: 'invoice_number',
            label: 'شماره فاکتور'
          },
          {
            key: 'customer',
            label: 'نام خریدار'
          },
          // { key: 'coupon', label: 'کوپن' },
          {
            key: 'AmountOrders',
            label: 'مبلغ فاکتور (تومان)'
          },
          {
            key: 'status',
            label: 'وضعیت صورتحساب'
          },
          {
            key: 'created_at',
            label: 'تاریخ ثبت'
          },
          {
            key: 'details',
            label: 'جزییات'
          }
        ],
        edit: {},
        disabled: false
      }
    },
    filters: {
      persianDate(date) {
        return window.moment(date).format('HH:mm jYYYY/jMM/jDD ')
      }
    },
    methods: {
      showInvoiceDetails(index) {
        let item = this.items[index]
        item.index = index;
        this.invoice = item
        this.$root.$emit('bv::show::modal', 'inovice-details')
      },
      numberFormat(num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      invoiceStatus() {
        this.disabled = true
        let form = document.getElementById('invoice-status')
        let formData = new FormData(form)
        this.$axios.post(this.$root.baseUrl + `/api/admin/change/status/${this.invoice.id}`, formData)
          .then(response => {
            window.swal({
              title: 'وضعیت با موفقیت تغییر کرد',
            })
            this.$root.$delete(this.items, this.invoice.index)
            this.$root.$emit('bv::hide::modal', 'inovice-details')
            this.disabled = false
          })
          .catch(error => {
            this.$root.error_notification(error)
            this.disabled = false
          })
      }
    },
    created() {
      
    }
  }
</script>