<template>
    <div>
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark"></b-spinner>
              <b-spinner type="grow" variant="dark"></b-spinner>
              <b-spinner small type="grow" variant="dark"></b-spinner>
            </div>
          </template>
      </b-overlay>
        <div class="row" v-if="! $root.isEmptyObject(stats)">
            <stats v-if="stats.invoices" to="/admin/sales" color="danger" icon="fas fa-list" info="سفارشات تایید نشده" :count="stats.invoices.total"></stats>
            <stats v-if="stats.comments" to="/admin/comments" color="success" icon="fas fa-comments" info="نظرات تایید نشده" :count="stats.comments.total"></stats>
            <stats v-if="stats.customers" to="/admin/customers" color="primary" icon="fas fa-users" info="تعداد کاربران" :count="stats.customers.total"></stats>
            <stats v-if="stats.products" to="/admin/products" color="secondary" icon="fas fa-shopping-cart" info="تعداد محصولات" :count="stats.products.total"></stats>
            <stats to="/admin/sales" color="warning" icon="fas fa-cart-arrow-down" info="فروش کل" :count="stats.sell_total"></stats>
            <stats to="/admin/sales" color="info" icon="fas fa-cart-arrow-down" info="فروش ماه" :count="stats.sell_moon"></stats>
            <stats to="/admin/sales" color="danger" icon="fas fa-cart-arrow-down" info="فروش هفته" :count="stats.sell_week"></stats>
            <stats to="/admin/sales" color="success" icon="fas fa-cart-arrow-down" info="فروش روز" :count="stats.sell_day"></stats>
        </div>
        <div class="row" v-if="! $root.isEmptyObject(stats)">
          <div class="col-md-12">
            <orders to="/admin/sales" :items="sales"></orders>
        </div>
            <div class="col-md-12">
                <contacts to="/admin/contacts" :items="contacts"></contacts>
            </div>
            <div class="col-md-12">
                <comments to="/admin/comments" :items="comments"></comments>
            </div>
           
        </div>
    </div>
</template>
<script>
import mixins from '../mixins/mixins'
import stats from './stats'
import contacts from './contacts'
import orders from './orders'
import comments from './comments'

export default {
  components: {
    stats,
    contacts,
    orders,
    comments
  },
  mixins: [mixins],
  data () {
    return {
      title: 'صفحه اصلی',
      stats: {},
      sales:{},
      comments:{},
      contacts:{},
      show: true
    }
  },
  mounted () {
    document.title = this.title
    this.$axios.get(this.$root.baseUrl + '/api/admin/stats')
    .then(response => {
      this.stats = response.data.data
        this.sales = this.stats.invoices.data
        this.comments = this.stats.comments.data
        this.contacts = this.stats.contacts.data
        this.show = false
    })
  },
}

</script>
